/* General styles for the burger menu icon */
.burger-menu-icon {
    background-color: transparent; /* Ensure the background is transparent */
    border: none; /* Remove any border if present */
    padding: 0; /* Remove any padding if present */
    margin: 0; /* Remove any margin if present */
    display: flex; /* Ensure it is displayed correctly */
    align-items: center; /* Center align items */
    justify-content: center; /* Center justify items */
}

.burger-menu-icon svg {
    fill: #000; /* Set the fill color of the SVG icon */
    width: 24px; /* Set the width of the icon */
    height: 24px; /* Set the height of the icon */
}

@media screen and (max-width: 450px) {
    /* Adjust the styling of the responsive menu */
    .md\:hidden {
      display: block;
      position: absolute;
      right: 0;
      z-index: 10;
      /* You can add more styles here as needed */
    }


    /* Ensure the burger menu button is styled correctly in mobile mode */
    .burger-menu-icon {
        background-color: transparent; /* Ensure the background is transparent */
        border: none; /* Remove any border if present */
        padding: 0; /* Remove any padding if present */
        margin: 0; /* Remove any margin if present */
    }

    .burger-menu-icon svg {
        fill: #000; /* Set the fill color of the SVG icon */
        width: 24px; /* Set the width of the icon */
        height: 24px; /* Set the height of the icon */
    }
}
