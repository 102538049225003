/* Classes for the Components of the current project */
/* Text Section From the Card Section*/

.text-section-container {
  margin-top: 1rem;
}

.text-center {
  text-align: center;
}
  
.text-header {
  font-size: 1.25rem;
  font-weight: bold;
}
  
.text-content {
  font-size: 1rem;
  color: #666;
}