.footer-container {
    margin-top: -48px; /* Assuming -mt-12 corresponds to -48px */
  }
  
  @media (max-width: 640px) {
    .footer-container {
      margin-top: 15px;
     
    }

    .footer-icons {
      margin-top: -40px;
      margin-left: 25px;
    }

  }
  